<template>
  <v-container class="show py-10">
    <v-row>
      <v-col cols="12" md="4">
        <v-img :src="item.cover_url"></v-img>

        <v-btn
          color="primary darken-2"
          block
          class="mt-5"
          :disabled="buying"
          @click="Buy"
        >
          <span v-if="buying">Loading... Please Wait !</span>
          <span v-else>Buy Now</span>
        </v-btn>
      </v-col>

      <v-col cols="12" md="7">
        <div class="product-detail">
          <div class="product-title text-h5">{{ item.title }}</div>
          <div class="text-overline">{{ item.qrcbn_id }}</div>
          <div class="product-publisher text-subtitle-1 mt-2">
            Publisher:
            <strong v-if="item.user.type"
              >{{ item.user.type }} {{ item.user.name }}</strong
            >
          </div>

          <div class="product-publisher text-h4 mt-5">
            {{ item.market.price | currency }}
          </div>

          <div class="item-info d-flex mt-3">
            <div class="item-info-item">
              <v-icon color="blue darken-2"> mdi-drama-masks </v-icon>
              <span class="text-overline ml-1">{{ item.readers }}</span>
            </div>

            <div class="item-info-item ml-3">
              <v-icon color="blue darken-2"> mdi-shape </v-icon>
              <span class="text-overline ml-1">{{ item.category }}</span>
            </div>

            <div class="item-info-item ml-3">
              <v-icon color="blue darken-2"> mdi-calendar-cursor </v-icon>
              <span class="text-overline ml-1"
                >Publish: {{ item.publish_date }}</span
              >
            </div>

            <div class="item-info-item ml-3">
              <v-icon color="blue darken-2"> mdi-book-open-page-variant-outline </v-icon>
              <span class="text-overline ml-1">{{ item.page }} Page</span>
            </div>
          </div>

          <div class="product-sinopsis text-caption mt-3 text-justify">
            {{ item.description }}
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Show",
  components: {},
  data: () => ({
    item: [],
    loadingData: false,

    buying: false,
    dialog: false,

    invoice_url: "",
  }),
  mounted() {
    this.getData();
  },
  methods: {
    Buy() {
      if (!localStorage.getItem("access_token")) {
        window.open("/login");
      } else {
        this.buying = true;
        axios
          .post("customer/buy", {
            id: this.item.market.id,
          })
          .then((response) => {
            // console.log(response);
            this.invoice_url = response.data.data.invoice.invoice_url;

            window.open(this.invoice_url, "_blank").focus();
            // this.$router.push("/transactions");
          })
          .catch((error) => {
            this.buying = false;
            console.log(error);
          });
      }
    },

    // Terima() {
    //   this.buying = true;

    //   axios
    //     .post("transactionAction", {
    //       id: 1,
    //       status: 2,
    //     })
    //     .then((response) => {
    //       console.log(response);
    //       this.buying = false;
    //       // this.$router.push("/transactions");
    //     })
    //     .catch((error) => {
    //       this.buying = false;
    //       console.log(error);
    //     });
    // },

    getData() {
      this.loadingData = true;

      axios
        .get("show/" + this.$route.params.id, {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.item = response.data.data.naskah;
          console.log(this.item);
          this.loadingData = false;
        })

        .catch((error) => {
          console.log(error);
          this.loadingData = false;
        });
    },
  },
};
</script>
<style>
.product-sinopsis {
  white-space: pre-wrap !important;
}
</style>
